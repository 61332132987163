<template>
  <section
    :id="`${anchor ? anchor : 'block-' + indexBlock}`"
    class="block-cta-accordion-image py-4 py-md-50 accordion-container"
    :class="getContainerBackground(componentData?.content_cta_accordion_image_container_background, componentData?.content_cta_accordion_image_container_add_bacteria)"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5 pt-4">
          <h2 v-if="componentData.content_cta_accordion_image_title" v-html="componentData.content_cta_accordion_image_title"></h2>
          <div v-if="accordionItems[0]" class="accordion accordion-small" id="accordion-cta">
            <div v-for="(item, index) in accordionItems" class="accordion-item">
              <h2 :id="'heading-cta-' + index" class="accordion-header">
                <button
                  class="accordion-button bu-accordion-button ps-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse-cta-'+ index"
                  aria-expanded="false"
                  :aria-controls="'collapse-cta-'+ index"
                  :data-img-src="item?.content_cta_accordion_image_image"
                  data-bu-container="cta-accordion-image"
									@click="clickItem(item, index)"
                >
                  <span
                    v-if="item.content_cta_accordion_image_icon_source && accordionIsOpen[index]"
                    class="icon-bu me-2"
                    v-html="item.content_cta_accordion_image_icon_source"
                  ></span>
                  {{ item.content_cta_accordion_image_title }}
                </button>
                <div class="bu-border" :style="item.content_cta_accordion_image_color? 'border-bottom: 2px solid ' + item.content_cta_accordion_image_color : 'border-bottom: 2px solid #000'"></div>
              </h2>
              <div
                :id="'collapse-cta-'+ index"
                class="accordion-collapse collapse"
                :aria-labelledby="'heading-cta-'+ index"
                data-bs-parent="#accordion-cta"
              >
                <div class="bu-border" :style="item.content_cta_accordion_image_color? 'border-bottom: 2px solid ' + item.content_cta_accordion_image_color : 'border-bottom: 2px solid #000'"></div>
                <div class="accordion-body ps-0 py-2">
                  <div v-if="item.content_cta_accordion_image_text" class="mb-3" v-html="item.content_cta_accordion_image_text"></div>
                  <template v-if="item.content_cta_accordion_image_cta_repeater_subfield" v-for="link in item.content_cta_accordion_image_cta_repeater_subfield">
                    <NuxtLink
                      v-if="link.content_cta_accordion_image_cta_style == 'button' && link.content_cta_accordion_image_cta_target == '_self'"
                      :to="$url(link.content_cta_accordion_image_cta_page_link? link.content_cta_accordion_image_cta_page_link : link.content_cta_accordion_image_cta_file)"
                      class="btn btn-sm btn-primary btn-icon me-3"
                      :target="link.content_cta_accordion_image_cta_target"
                      :style="'background-color:' + item.content_cta_accordion_image_color + '; border-color:' + item.content_cta_accordion_image_color"
                    >
                      {{ link.content_cta_accordion_image_cta_label }}&nbsp;<span class="rotate-180"><ArrowIcon v-if="link.content_cta_accordion_image_add_arrow == 1 && accordionIsOpen[index]" /></span>
                    </NuxtLink>
                    <a
                      v-if="link.content_cta_accordion_image_cta_style == 'button' && link.content_cta_accordion_image_cta_target == '_blank'"
                      :href="link.content_cta_accordion_image_cta_page_link? link.content_cta_accordion_image_cta_page_link : link.content_cta_accordion_image_cta_file"
                      class="btn btn-sm btn-primary btn-icon me-3"
                      :target="link.content_cta_accordion_image_cta_target"
                      :style="'background-color:' + item.content_cta_accordion_image_color + '; border-color:' + item.content_cta_accordion_image_color"
                    >
                      {{ link.content_cta_accordion_image_cta_label }}&nbsp;<span class="rotate-180"><ArrowIcon v-if="link.content_cta_accordion_image_add_arrow == 1 && accordionIsOpen[index]" /></span>
                    </a>
                    <NuxtLink
                      v-if="link.content_cta_accordion_image_cta_style == 'link' && link.content_cta_accordion_image_cta_target == '_self'"
                      :to="$url(link.content_cta_accordion_image_cta_page_link? link.content_cta_accordion_image_cta_page_link : link.content_cta_accordion_image_cta_file)"
                      class="btn btn-sm btn-primary btn-icon me-3"
                      :target="link.content_cta_accordion_image_cta_target"
                      :style="'background-color:' + item.content_cta_accordion_image_color + '; border-color:' + item.content_cta_accordion_image_color"
                    >
                      {{ link.content_cta_accordion_image_cta_label }}&nbsp;<span class="rotate-180"><ArrowIcon v-if="link.content_cta_accordion_image_add_arrow == 1 && accordionIsOpen[index]" /></span>
                    </NuxtLink>
                    <a
                      v-if="link.content_cta_accordion_image_cta_style == 'link' && link.content_cta_accordion_image_cta_target == '_blank'"
                      :href="link.content_cta_accordion_image_cta_page_link? link.content_cta_accordion_image_cta_page_link : link.content_cta_accordion_image_cta_file"
                      :target="link.content_cta_accordion_image_cta_target"
                      class="text-decoration-underline me-3"
                    >
                      {{ link.content_cta_accordion_image_cta_label }}&nbsp;<span class="rotate-180"><ArrowIcon v-if="link.content_cta_accordion_image_add_arrow == 1 && accordionIsOpen[index]" /></span>
                    </a>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-none d-lg-block col-lg-6 offset-lg-1">
          <figure v-if="componentData?.content_cta_accordion_image_default_image?.format?.large[0] || activeImage.content_cta_accordion_image_image" class="figure with-shadow">
            <NuxtImg
							loading="lazy"
              :src="activeImage != ''? activeImage : componentData?.content_cta_accordion_image_default_image?.format?.large[0]" 
              class="figure-img img-fluid rounded-4"
              id='accordion-image-default-cta'
              :alt="componentData?.content_cta_accordion_image_default_image.alt ? componentData?.content_cta_accordion_image_default_image.alt : 'CTA image'"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import ArrowIcon from '../../icons/arrow.vue';
  const { $url } = useNuxtApp();

	const props = defineProps({
		data: Object,
		index: Number,
	});

	// data
	const componentData = props?.data?.data || {};
	const anchor = props?.data?.anchor || '';
	const indexBlock = props?.index || '1';

	// computed
	const accordionItems = componentData?.content_cta_accordion_image_repeater || {};
	let activeItem = componentData?.content_cta_accordion_image_default_image || {};

	// reactive states
	const activeImage = ref('');
	let imageSize = ref(getImageSize());

	const generateAccordionItemsState = () => {
		let out = {};
		Object.keys(accordionItems).forEach((key, index) => {
			out[key] = false;
		})

		return out;
	}
	const accordionIsOpen = ref(generateAccordionItemsState());

	// methods
	const clickItem = (item, index) => {
		getImageSrc(item.content_cta_accordion_image_image);
		activeItem = item;

		accordionIsOpen.value[index] = !accordionIsOpen.value[index];
	}

	onMounted(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      if (componentData?.content_cta_accordion_image_default_image) {
        getImageSrc(componentData?.content_cta_accordion_image_default_image);
      }
    }
  });

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', onResize);
    }
  });

  function onResize() {
    imageSize = getImageSize();
    getImageSrc(activeItem);
  }

  function getImageSrc(image) {
    if (typeof window !== 'undefined') {
      imageSize = getImageSize();
      activeImage.value = image.format[imageSize][0] || '';
    }
  }

	 //console.log(' ');
	 // console.log(' ');
	 //console.log(' ');
	 //console.log('-- cta-accordion-image --');
	 //console.log('componentData: ', componentData);
</script>

<style lang="scss" scoped>
.block-cta-accordion-image {
  .accordion-button {
    font-family: "bebas-neue-pro", Arial, helvetica, sans-serif;
    background: transparent;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 110%;
    color: var(--primary);
    font-size: 1.5rem;
    padding: 10px 0 2px;
    overflow: hidden;

    &::after {
      width: var(--bs-accordion-btn-icon-width);
    }
  }

  .accordion-button.collapsed {
    border-bottom: 1px solid var(--primary_rgb_light);

    &::after {
      display: none;
    }

    .icon-bu {
      margin-left: -45px;

      svg:not(#cosmetics-svg) {
        fill: var(--primary_hal);

        path {
          stroke: var(--primary_hal);
          fill: var(--primary_hal);
        }
      }

      #cosmetics-svg {
        path {
          stroke: var(--primary_hal);
        }
      }
    }
  }

  .accordion-header {
    background: transparent;
    border: 0;

    &:hover {
      .icon-bu {
        margin-left: 1px;
      }

      .accordion-button {
        border-bottom: 0;

        &::after {
          display: block;
        }
      }

      .bu-border {
        width: 100%;
      }
    }
  }

  .accordion-collapse {
    margin-top: -2px;

    .bu-border {
      width: 100%;
    }
  }

  .accordion-item {
    background: transparent;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.bu-border {
  width: 0;
  transition: all 0.8s;
}

@media (max-width: 991px) {
	.block-cta-accordion-image {
    h2:not(.accordion-header) {
      text-align: center;
    }

    .accordion-button {
      font-size: 1.1rem;
      padding: 5px 0 2px;
    }
  }
}
</style>
